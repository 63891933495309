import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import { getThemeRoutes } from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import { withMsal, WithMsalProps } from '@azure/msal-react';
import { AuthenticationResult, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import './assets/scss/style.scss';
import { LOCAL_STORAGE_KEYS, REDIRECT_ERROR_CODES, SystemReadingZone } from './utils';
import CommonService from './services/common/common.service';
import { setAssignedPermissions, setPermissions, setAbilities, setUserSetting } from './redux/settings/Action';
import { bindActionCreators, Dispatch } from 'redux';
import { ExtendedStore, IDataPermissions } from './types';
import { RawRule } from '@casl/ability';
import _ from 'lodash';
import StandardResponse from './services/standard-response.model';
import ErrorToast from './components/error-toast';
import Spinner from './views/spinner/Spinner';
import { ChangeDarkMode, ChangeSidebarColor, ChangeTopbarColor } from './store/customizer/CustomizerSlice';
import PageLoader from './components/loader/page-loader';

interface IPermissions {
  [key: string]: number;
}

interface IUserClaims {
  email: string;
  id: number;
  is_active: boolean;
  permissions: string;
  user_name: string;
}

interface ITokenClaims {
  acr: string;
  aud: string;
  auth_time: number;
  city: string;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  name: string;
  nbf: number;
  nonce: string;
  sub: string;
  tid: string;
  userData: string;
  ver: string;
}

interface IAppProps extends WithMsalProps {
  dispatch: any;
}

interface IAppState {
  isReady: boolean;
  userNotFound: boolean;
  isPasswordReset: boolean;
}

const mapDispatchtoProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);
const darkClassName = 'dark';

const RendeApp = (props: any) => {
  const customizer = useSelector((state: ExtendedStore) => state.customizer);
  let direction = false;
  let isMode = false;
  let themeName = '';

  if (customizer) {
    direction = customizer.isRTL;
    isMode = customizer.isDark;
    if (isMode) {
      document.body.classList.add(darkClassName);
      themeName = 'dark';
    } else {
      document.body.classList.remove(darkClassName);
      themeName = 'light';
    }
  }

  const routing = useRoutes(getThemeRoutes(props));

  return (
    <>
      <Spinner />
      <div
        className={`${direction ? 'rtl' : 'ltr'} ${isMode ? `${darkClassName}` : ''}`}
        dir={direction ? 'rtl' : 'ltr'}
        id="main-wrapper-new"
        data-theme={themeName}
      >
        <ThemeSelector />
        {routing}
      </div>
    </>
  );
};

class AppClone extends React.Component<IAppProps, IAppState> {
  commonService = new CommonService();
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      isReady: false,
      userNotFound: false,
      isPasswordReset: false,
    };
    this.init = this.init.bind(this);
  }

  async componentDidMount() {
    this.init();
    // SystemReadingZone('Australia/Melbourne');
  }

  setAbilitiesRedux = (response: StandardResponse<any>) => {
    const data = (response.data || []) as IDataPermissions[];
    data.map((item) =>
      item.permissions.forEach((permission) => {
        let permissionKey = permission.permission;

        if (permissionKey === 'View') {
          permissionKey = `${item.module} ${permissionKey}`;
        }

        permission.permission = permissionKey.replace(/ /g, '');
      }),
    );
    this.props.dispatch(setPermissions(data));
    const userData = this.getUserClaim();

    if (userData) {
      const userDetails = JSON.parse(userData) as IUserClaims;
      const permissions = JSON.parse(userDetails.permissions) as IPermissions;
      this.props.dispatch(setAssignedPermissions(permissions));
      const abilities = new Array<RawRule>();
      Object.keys(permissions).forEach((key) => {
        let modulePermissionValue = permissions[key];
        const moduleAllPermission = data.find(
          (modulePermission) => modulePermission.module.toLowerCase() === key.toLowerCase(),
        );
        const descendingPermission = _.orderBy(
          moduleAllPermission?.permissions,
          ['value'],
          ['desc'],
        );
        descendingPermission
          .filter((item) => item.value <= permissions[key])
          .forEach((permissionValue) => {
            const { value, permission } = permissionValue;
            const substractedValue = modulePermissionValue - value;
            if (substractedValue >= 0) {
              modulePermissionValue -= value;
              abilities.push({
                subject: value.toString(),
                action: permission,
              });
            }
          });
      });
      this.props.dispatch(setAbilities(abilities));
    }
  };

  getPermissionsAndStoreInRedux = async () => {
    const userData = this.getUserClaim();

    if(userData){

      const response = await this.commonService.getPermissions();
      this.setAbilitiesRedux(response);
      await this.getAndSetUserProfileInRedux();
    }
  };

  getAndSetUserProfileInRedux = async () => {
    const userProfile = await this.commonService.GetUserProfile();
    if (userProfile && userProfile.data) {
      const {
        sidebarColor,
        themeType,
        topbarColor
      } = userProfile.data;

      if (sidebarColor) {
        this.props.dispatch(ChangeSidebarColor(sidebarColor));
      }

      if (themeType) {
        this.props.dispatch(ChangeDarkMode(themeType === "dark"));
      }

      if (topbarColor) {
        this.props.dispatch(ChangeTopbarColor(topbarColor));
      }
      
      this.props.dispatch(setUserSetting(userProfile.data));
    }
  }

  getUserClaim = () => {
    const loggedAccount = this.props.msalContext.instance.getActiveAccount();
    if (loggedAccount) {
      if (loggedAccount.idTokenClaims) {
        const claims = loggedAccount.idTokenClaims as ITokenClaims;
        return claims.userData;
      }
    }
    return '';
  };

  getSystemConfigurationAnsStore = async () => {
    const commonService = new CommonService();
    localStorage.removeItem(LOCAL_STORAGE_KEYS.SYSTEM_CONFIG);
    await commonService.GetSystemConfiguration().then((res) => {
      if (res.data && res.data.length) {
        let systemConfig = {};
        res.data.forEach(
          (item: { configuration_name: string | number; configuration_value: any }) => {
            systemConfig = {
              ...systemConfig,
              [item.configuration_name]: item.configuration_value,
            };
          },
        );
        localStorage.setItem(LOCAL_STORAGE_KEYS.SYSTEM_CONFIG, JSON.stringify(systemConfig));
      }
    });
  };

  redirectToLoginAfterPasswordReset = (res: AuthenticationResult | null) => {
    if (res) {
      const idTokenClaims = res.idTokenClaims as unknown as any;
      const acrList = ['b2c_1a_passwordreset'];
      if (acrList.includes(idTokenClaims.acr)) {
        const authority = msalConfig.auth.authority;
        localStorage.clear();
        this.setState({ isPasswordReset: true });
        this.props.msalContext.instance.loginRedirect({
          authority,
          scopes: [],
        });
        return false;
      }
    }
  };

  init = () => {
    const redirectToPasswordReset = (event: any) => {
      let authority = msalConfig.auth.authority;
      if (
        event.error &&
        event.error.errorMessage.indexOf(REDIRECT_ERROR_CODES.FORGOT_PASSWORD) > -1
      ) {
        authority = msalConfig.auth.passwordResetURL;
      }

      this.props.msalContext.instance.loginRedirect({
        authority,
        scopes: [],
      });
    }
    this.props.msalContext.instance
      .handleRedirectPromise()
      .then(async (res) => {
        this.redirectToLoginAfterPasswordReset(res);
        let account = this.props.msalContext.instance.getActiveAccount();
        if (res && res.account) {
          account = res.account;
        }
        if (!account) {
          this.props.msalContext.instance.loginRedirect();
        } else {
          localStorage.setItem('idToken', (res?.idToken ?? ""));
          this.props.msalContext.instance.setActiveAccount(account);
          await this.getPermissionsAndStoreInRedux();
          const userData = this.getUserClaim();
          if (userData) {
            await this.getSystemConfigurationAnsStore();
            this.setState({ isReady: true });
          } else {
            this.setState({ userNotFound: true });
          }
        }
      })
      .catch((error) => {
        redirectToPasswordReset({ error });
      });
    this.props.msalContext.instance.addEventCallback(async (event: any) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        redirectToPasswordReset(event);
      }
    });
  };

  render() {
    const { isReady, userNotFound } = this.state;
    return (
      <>
        {userNotFound && !isReady && (
          <>
            <ErrorToast
              buttonText="OK"
              onConfirm={() => {
                this.setState({ userNotFound: false });
                localStorage.clear();
                this.props.msalContext.instance.logoutRedirect({
                  postLogoutRedirectUri: '/',
                });
              }}
              message="User does not exist. Please contact your administrator."
            />
          </>
        )}
        {!isReady && !userNotFound && (
          <>
            <PageLoader />
          </>
        )}

        {isReady && !userNotFound && (
          <>
            <RendeApp {...this.props} />
          </>
        )}
      </>
    );
  }
}

const App = withMsal(connect(mapDispatchtoProps)(AppClone));
export default App;

// const App = () => {
//   const routing = useRoutes(Themeroutes);
//   const direction = useSelector((state) => state.customizer.isRTL);
//   const isMode = useSelector((state) => state.customizer.isDark);
//   return (
//     <div
//       className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
//       dir={direction ? 'rtl' : 'ltr'}
//     >
//       <ThemeSelector />
//       {routing}
//     </div>
//   );
// };

// export default App;
